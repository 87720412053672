import React, { useContext, useEffect, useState } from "react"
// @ts-ignore
import { useIntl } from "react-intl"
import styled from "styled-components"
import TabTableWrapper from "../TabTableWrapper"
import useBigScreen from "@/hooks/bigScreen"
import { getAdaptByCountry } from '@/services/request'

const TabWrapper = styled.div`
  max-width: 1000px;
  margin: 0px auto;
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
`
const hlColor = `#0061FF`

const Tab = styled.div`
  display: block;
  position: relative;
  padding: 0 15px;
  font-size: 16px;
  margin: 15px 0px 0;
  line-height: 33px;
  height: 40px;
  color: ${props => (props.hl ? hlColor : "#D0D3D7")};
  font-weight: bold;
  transition: all 0.2s;
  flex: auto;
  cursor: pointer;
  text-align: center;
  &:hover {
    color: ${hlColor};
  }
  &:after {
    transition: all 0.2s;
    content: "";
    position: absolute;
    bottom: ${props => (props.hl ? `1px` : "0px")};
    left: 0;
    right: 0;
    height: ${props => (props.hl ? `3px` : "3px")};
    background-color: ${props => (props.hl ? hlColor : "#D0D3D7")};
    pointer-events: none;
  }
  @media screen and (max-width: 800px) {
    & {
      flex: none;
      width: 100%;
    }
  }
`
const CompatibilityTable = () => {
  const [currentCate, setCate] = useState(4)
  const intl = useIntl()
  const text = {
    tabs1: intl.formatMessage({ id: `Support.Japan` }),
    tabs2: intl.formatMessage({ id: `Support.SouthKorea` }),
    tabs3: intl.formatMessage({ id: `NebulaPpTos.Spain` }),
    tabs4: intl.formatMessage({ id: `Support.Germany` }),
    tabs5: intl.formatMessage({ id: `Product.CD.CompatibleDevices_US` }),
    tabs6: intl.formatMessage({ id: `Support.GB` }),
  }
  const tabs = [
    text.tabs1,
    text.tabs2,
    text.tabs3,
    text.tabs4,
    text.tabs5,
    text.tabs6,
    // text.tabs6,
    // text.tabs7,
    // text.tabs8,
    // text.tabs9,
  ]
  const countryCode = ["JP", "KR", "ES", "DE", "US", "GB"
    //  "GB", "FR", "CN", "OTHER"
  ]

  const bigScreen = useBigScreen(656)
  // table data
  const [tbdata, setTbdata] = useState([])
  const getTbData = p => {
    getAdaptByCountry({ countryCode: p }).then(
      (d: object) => {
        console.log(d)
        // @ts-ignore
        if (d.data.code === 200) {
          // @ts-ignore
          if (d.data.data !== null && d.data.data.length > 0) {

            // @ts-ignore
            setTbdata(d.data.data)
          } else {
            setTbdata([])
          }
          // @ts-ignore
        } else throw d.data
      }
    )
  }

  useEffect(() => {
    getTbData(countryCode[currentCate])
  }, [currentCate])
  const renderFnDevi = text => {
    let arr = []
    for (var i = 0; i < text.length; i++) {
      // arr.push("Nreal " + text[i])
      arr.push(text[i])
    }
    return arr.length > 0 ? arr.join(", ") : arr
  }
  const renderFn = text => {
    return text ? (
      <svg
        width="18"
        height="15"
        viewBox="0 0 18 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 7.61702L6.74194 13L16 2"
          stroke="#62E760"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ) : (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.09949 12L11.9999 2"
          stroke="#AFAFAF"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M11.9005 12L2.00008 2"
          stroke="#AFAFAF"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>
    )
  }
  const columns = [
    {
      title: intl.formatMessage({ id: `Nebula.phone.tablehd.title1` }),
      dataIndex: "modelName",
      key: "modelName",
      // render: text => <div style={{ textAlign: "left" }}>{text}</div>,
      align: "center",
    },
    {
      title: intl.formatMessage({ id: `Nebula.phone.tablehd.title2` }),
      dataIndex: "modelNumber",
      key: "modelNumber",
      align: "center",
    },
    {
      title: intl.formatMessage({ id: `Nebula.phone.tablehd.title3` }),
      dataIndex: "airCasting",
      key: "airCasting",
      align: "center",
      width: bigScreen ? "" : 70,
      render: text => renderFn(text),
    },
    {
      title: intl.formatMessage({ id: `Nebula.phone.tablehd.title4` }),
      key: "mrspace",
      dataIndex: "mrspace",
      align: "center",
      width: bigScreen ? "" : 70,
      render: text => renderFn(text),
    },
    {
      title: intl.formatMessage({ id: `Nebula.phone.tablehd.title5` }),
      key: "devices",
      dataIndex: "devices",
      align: "center",
      width: bigScreen ? "" : 74,
      render: text => renderFnDevi(text),
    },
  ]
  return (
    <>
      <TabWrapper>
        {tabs.map((k, i) => (
          <Tab key={k} onClick={() => setCate(i)} hl={i === currentCate}>
            <span>{k}</span>
          </Tab>
        ))}
      </TabWrapper>
      {tbdata.length > 0 ? (
        <TabTableWrapper
          tbhead={columns}
          tbdata={tbdata}
          currentCate={currentCate}
        />
      ) : (
        ""
      )}
    </>
  )
}

export default CompatibilityTable
