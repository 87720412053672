import React, { useState, useEffect } from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import { useIntl } from "react-intl"
import * as S from "@/components/Shop"
import styled from "styled-components"
import CompatibilityTable from "@/components/compatibility-list/compatibilityTable"

const SpecsContainer = styled.div`
  position: relative;
  width: calc(100% - 40px);
  max-width: 1000px;
  height: auto;
  min-height: 100vh;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0);
  color: black;
  font-size: 14px;
  letter-spacing: 0.05em;
  @media screen and (max-width: 480px) {
		letter-spacing: normal;
	}
}
`
const Title = styled.div`
  font-size: 40px;
  font-weight: bold;
  color: #000;
  text-align: center;
  margin: 0 auto;
`
const Index = () => {
  const intl = useIntl()

  return (
    <Layout menuBlack={true}>
      <SEO page="Compatibility"/>
      <SpecsContainer>
        <S.Spacer h={100} />
        <Title>{intl.formatMessage({ id: `compatibilityPage.text1` })}</Title>
        <S.Spacer h={100} />
        <CompatibilityTable />
        <S.Spacer h={50} />
        <div className="txTips">
          <span
            dangerouslySetInnerHTML={{
              __html: `${intl.formatMessage({
                id: "compatibilityPage.text2",
              })}`,
            }}
          />
        </div>
        <S.Spacer h={50} />
        <div className="txTips">
          <span
            dangerouslySetInnerHTML={{
              __html: `${intl.formatMessage({
                id: "compatibilityPage.text3",
              })}`,
            }}
          /><br/>
          <span
            dangerouslySetInnerHTML={{
              __html: `${intl.formatMessage({
                id: "compatibilityPage.text4",
              })}`,
            }}
          /><br/>
          <span
            dangerouslySetInnerHTML={{
              __html: `${intl.formatMessage({
                id: "compatibilityPage.text5",
              })}`,
            }}
          /><br/>
          <span
            dangerouslySetInnerHTML={{
              __html: `${intl.formatMessage({
                id: "compatibilityPage.text6",
              })}`,
            }}
          /><br/>
          <span
            dangerouslySetInnerHTML={{
              __html: `${intl.formatMessage({
                id: "compatibilityPage.text7",
              })}`,
            }}
          />
        </div>
        <S.Spacer h={250} />
      </SpecsContainer>
    </Layout>
  )
}

export default Index
